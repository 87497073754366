<template>
  <div>
    <b-dropdown
      variant="link"
      toggle-class="add-account-btn text-decoration-none rounded-0 p-md-0"
      right
      no-caret
      @toggle="showingDropdown = !showingDropdown"
    >
      <template #button-content>
        <div
          id="add-new-wallet-btn"
          class="dropdown-btn d-flex justify-content-center align-items-center"
        >
          <feather-icon icon="PlusIcon" />
          <p class="mb-0">
            New Account
          </p>
          <feather-icon :icon="showingDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
        </div>
      </template>
      <!-- Add Percent Based -->
      <b-dropdown-item
        @click="$emit('add-percent-based')"
      >
        <div
          class="d-flex"
          :class="{'align-items-center': !IS_MOBILE()}"
        >
          <feather-icon
            icon="PercentIcon"
            class="mr-1"
            :size="IS_MOBILE() ? '42' : '24'"
          />
          <div>
            <p class="mb-0 font-weight-bold">
              Add Percent Based Account
            </p>
            <div :style="IS_MOBILE() ? 'textWrap: auto; width: 100%;' : ''">
              <small>allows you to make alt-accounts with certain proportion</small>
            </div>
          </div>
        </div>
      </b-dropdown-item>

      <!-- Add Open Wallet -->
      <b-dropdown-item
        @click="$emit('add-open-wallet')"
      >
        <div
          class="d-flex"
          :class="{'align-items-center': !IS_MOBILE()}"
        >
          <feather-icon
            icon="DiscIcon"
            class="mr-1"
            :size="IS_MOBILE() ? '42' : '24'"
          />
          <div>
            <p class="mb-0 font-weight-bold">
              Add Open Account
            </p>
            <div :style="IS_MOBILE() ? 'textWrap: auto; width: 100%;' : ''">
              <small>allows you to make alt-accounts without any proportions</small>
            </div>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      showingDropdown: false,
      familyTypeToCreate: '',
      createWallet: false,
    }
  },
}
</script>
