<template>
  <div>
    <div class="pl-0">
      <!-- Configure Account Alert -->
      <b-alert
        v-if="!wallet"
        show
        variant="warning"
        class="small text-warning cursor-pointer p-1"
      >
        <div
          class="d-flex align-items-center"
          @click="$router.push({ name: `wallet-accounts`, params: { userType, default: true } })"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            class="mr-1"
          />
          <p>{{ $t('actions.configure-account') }}</p>
        </div>
      </b-alert>
    </div>

    <!-- Alt Accounts Table -->
    <b-overlay :show="isLoading">
      <b-card class="wallet-table mb-0">
        <!-- Table Header -->
        <div class="table-header d-flex align-items-center">
          <h6
            v-for="field in fields"
            :key="field.key"
            :class="field.classes"
          >
            {{ $t(field.label) }}
          </h6>
        </div>

        <!-- Table Contents -->

        <!-- Mobile View without Draggable -->
        <div v-if="altAccounts.length && IS_MOBILE()">
          <alt-account-table
            :hovered-account="hoveredAccount"
            :alt-accounts="altAccounts"
            :family-type="familyType"
            :user-type="userType"
            :show-balance="showBalance"
            :wallet-family="walletFamily"
            :accounts="accounts"
            :wallet="wallet"
            :selected-wallet="selectedWallet"
            :index="index"
            @show-modal="(account, id) => showModal(account, id)"
            @update-hovered-account="uuid => hoveredAccount = uuid"
            @balance-toggle="showBalance = !showBalance"
            @set-selected-wallet="wallet => selectedWallet = wallet"
          />
        </div>

        <!-- Draggable View with Draggable -->
        <draggable
          v-else-if="altAccounts.length"
          v-model="altAccounts"
          @end="orderAltAccounts"
        >
          <div
            v-for="account, accIndex in altAccounts"
            :key="account.uuid"
            class="cursor-move"
            @mouseenter="hoveredAccount = account.uuid"
            @mouseleave="hoveredAccount = null"
          >
            <div
              class="d-flex align-items-center table-row cursor-pointer"
              :class="{striped: accIndex%2}"
            >
              <!-- Account Name -->
              <div
                class="d-flex align-items-center ml-1 py-1"
                :class="familyType === 'PERCENTBASED' ? 'col-5' : 'col-6'"
              >
                <!-- Drag Icon -->
                <div class="drag-btn">
                  <i
                    v-if="hoveredAccount === account.uuid && altAccounts.length > 1"
                    class="fas fa-grip-vertical cursor-move pr-1"
                  />
                </div>
                <!-- Name Content -->
                <b-avatar
                  size="55"
                  class="mr-2"
                >
                  <i
                    class="f-25"
                    :class="getWalletIcon(account.wallet.__typename)"
                  />
                </b-avatar>
                <div>
                  <h6 class="mb-0 mt-0-5">
                    {{ account.description }}
                  </h6>
                  <div v-if="account.wallet.__typename === 'BankWallet'">
                    <p class="small mb-0">
                      {{ account.wallet.information.bankName }}
                    </p>
                    <p class="small mb-0">
                      {{ account.wallet.information.bankAccount }}
                    </p>
                    <b-link
                      v-if="hasHigherPermission"
                      class="small d-flex align-items-center"
                      :class="{ 'no-opacity': hoveredAccount !== account.uuid }"
                      :to="{ name: userType === 'users' ? 'user-general-settings' : 'companySetting', hash: '#banking', params: {id: account.wallet.information.id, fromPage: 'accounts'} }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span>{{ $t('Edit Bank Details') }}</span>
                    </b-link>
                  </div>
                  <p
                    v-else
                    class="small mb-0"
                  >
                    {{ getWalletType(account.wallet.__typename) }}
                  </p>
                  <small
                    v-if="account.sharedTo && (account.owner && !isWalletShared(account, userType))"
                    id="shared-info"
                  >
                    <feather-icon icon="LinkIcon" />
                    {{ $t('Shared with') }}
                    <span v-if="account.sharedTo">
                      <i
                        class="ml-2"
                        :class="account.sharedTo.__typename === 'User' ? 'fas fa-user' : 'fas fa-building'"
                      />
                      {{ account.sharedTo.name }}
                    </span>
                    <b-badge
                      class="d-block mt-0-5 py-0-5"
                      variant="wz-teal"
                    >
                      <i
                        class="fa"
                        :class="GET_SHARED_DIRECTION(account.shareDirection, {icon: true})"
                      />
                      {{ GET_SHARED_DIRECTION(account.shareDirection) }}
                    </b-badge>
                  </small>
                  <small
                    v-else-if="account.invites.total"
                    v-b-tooltip.hover.bottom="`Shared with ${account.invites.data[0].sharedTo.name} - Pending`"
                  >
                    <i class="fa fa-stopwatch" />
                    {{ $t('Share Invitation Pending') }}
                  </small>
                </div>
              </div>

              <!-- Share Percent -->
              <div
                v-if="familyType === 'PERCENTBASED'"
                class="col-3"
              >
                <span v-if="isWalletShared(account, userType)">-</span>
                <span
                  v-else
                  class="pl-2"
                >{{ account.sharePercent }}%</span>
              </div>

              <!-- Total Balance -->
              <div
                :class="familyType === 'PERCENTBASED' ? 'col-2' : 'col-4'"
                @click.stop="() => $emit('balance-toggle')"
              >
                <span v-if="!showBalance">
                  XXX.XX
                </span>

                <span v-else>{{ formatAmount(account.balance ? account.balance : 0) }}</span>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
                />
              </div>

              <!-- Actions -->
              <div
                id="tour-account-actions"
                class="col-2 text-right d-flex justify-content-between pr-2"
              >
                <!-- Buttons -->
                <div class="d-flex justify-content-end w-100">
                  <!-- View Statement -->
                  <b-button
                    v-b-tooltip.hover="$t('View Statement')"
                    variant="primary"
                    class="edit-btn mr-1"
                    @click.stop="$router.push({ name: 'wallet-statement', params: { account: account.uuid, userType, walletFamily: wallet.uuid} })"
                  >
                    <i class="fa fa-eye" />
                  </b-button>

                  <!-- Transfer Fund -->
                  <span
                    v-if="(account.owner && !isWalletShared(account, userType)) || (!isWalletShared(account, userType) || account.shareDirection !== 'INBOUND')"
                    v-b-tooltip.hover
                    :title="account.balance < 1 ? $t('Insufficient Fund') : $t('Transfer Fund')"
                  >
                    <b-button
                      class="edit-btn mr-1"
                      :variant="account.balance < 1 || (walletFamily.length < 2 && accounts.length < 2) ? 'secondary' : 'success'"
                      :disabled="account.balance < 1 || (walletFamily.length < 2 && accounts.length < 2)"
                      @click.stop="() => { showModal(account, `transfer-fund-${index}-${userType}-modal`) }"
                    >
                      <i class="fa fa-random" />
                    </b-button>
                  </span>

                  <!-- Withdraw Fund -->
                  <span
                    v-b-tooltip.hover
                    :title="account.balance < 1 ? $t('Insufficient Fund') : $t('Withdraw Fund')"
                  >
                    <b-button
                      v-if="account.wallet.__typename === 'BankWallet'"
                      class="edit-btn mr-1"
                      :variant="account.balance < 1 ? 'secondary' : 'success'"
                      :disabled="account.balance < 1"
                      @click.stop="() => { showModal(account, `withdraw-fund-${index}-${userType}-modal`) }"
                    >
                      <i class="fa fa-circle-dollar-to-slot" />
                    </b-button>
                  </span>
                </div>

                <b-dropdown
                  v-if="account.owner && !isWalletShared(account, userType)"
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="24"
                    />
                  </template>
                  <!-- Edit Wallet Alias -->
                  <b-dropdown-item
                    @click.stop="showModal(account, `edit-wallet-${index}-${userType}-modal`)"
                  >
                    <i class="fa fa-edit mr-1" />
                    {{ $t('Rename') }}
                  </b-dropdown-item>

                  <!-- Attach Internal Wallet to Bank -->
                  <b-dropdown-item
                    v-if="account.wallet.__typename === 'InternalWallet' && hasHigherPermission"
                    @click.stop="showBankingInfoModal(account)"
                  >
                    <i class="fa fa-link mr-1" />
                    {{ $t('Link with Bank A/c') }}
                  </b-dropdown-item>

                  <!-- Split Wallet -->
                  <b-dropdown-item
                    v-if="familyType === 'PERCENTBASED'"
                    @click.stop="showModal(account, `split-account-${index}-${userType}-modal`)"
                  >
                    <i class="fa fa-share-alt mr-1" />
                    {{ $t('Split Account') }}
                  </b-dropdown-item>

                  <!-- Merge Wallet -->
                  <b-dropdown-item
                    v-if="familyType === 'PERCENTBASED' && showMergeWalletOption(account)"
                    @click.stop="showModal(account, `merge-accounts-${index}-${userType}-modal`)"
                  >
                    <i class="rotate-180 fa fa-project-diagram mr-1" />
                    {{ $t('Merge Other Accounts') }}
                  </b-dropdown-item>

                  <!-- Make Reconciliation -->
                  <b-dropdown-item
                    v-if="userType === 'company'"
                    @click.stop="showModal(account, `reconcile-transaction-${index}-${userType}-modal`)"
                  >
                    <i class="fa fa-receipt mr-1" />
                    {{ $t('Add Manual Transaction') }}
                  </b-dropdown-item>

                  <!-- Share / Revoke Share Wallet -->
                  <b-dropdown-item
                    v-if="hasHigherPermission && account.wallet.__typename === 'InternalWallet' && !account.invites.total"
                    :variant="account.sharedTo ? 'danger' : ''"
                    @click.stop="() => { walletAction = account.sharedTo ? 'revoke-share' : null; showModal(account, account.sharedTo ? `revoke-sharing-${index}-${userType}-modal` : `share-wallet-${index}-${userType}-modal`) }"
                  >
                    <i
                      class="fa mr-1"
                      :class="account.sharedTo ? 'fa-unlink' : 'fa-share'"
                    />
                    {{ account.sharedTo ? $t('Revoke Sharing') : $t('Share Account') }}
                  </b-dropdown-item>

                  <!-- Deactivate Wallet -->
                  <b-dropdown-item
                    v-if="familyType === 'OPENWALLET'"
                    variant="danger"
                    @click.stop="showModal(account, `deactivate-wallet-${index}-${userType}-modal`, 'deactivate')"
                  >
                    <i class="fa fa-exclamation-triangle mr-1" />
                    {{ $t('Deactivate Account') }}
                  </b-dropdown-item>

                  <!-- Revoke Share Invitation -->
                  <b-dropdown-item
                    v-if="hasHigherPermission && account.invites.total"
                    variant="danger"
                    @click.stop="() => { walletAction === 'revoke-invitatioin'; showModal(account, `revoke-wallet-${index}-${userType}-modal`) }"
                  >
                    <i class="fa fa-unlink mr-1" />
                    {{ $t('Revoke Share Invitation') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </draggable>
        <div v-else>
          <p class="mb-0 text-center">
            {{ $t('table.no-accounts') }}
          </p>
        </div>
      </b-card>
    </b-overlay>

    <!-- Modals -->
    <!-- Select Banking Info Modal -->
    <b-overlay :show="isLoading">
      <select-banking-info
        v-if="selectedWallet"
        :banking-info="availableBanks"
        :user-type="userType"
        :index="index"
        :selected-wallet="selectedWallet"
        :attach-wallet="true"
        @refetch="getBankingInfo"
        @payment-select="infoId => { attachWallet(infoId) }"
      />
    </b-overlay>

    <!-- Edit Alias Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`edit-wallet-${index}-${userType}-modal`"
      :title="$t('Rename Account')"
      hide-footer
      no-close-on-backdrop
    >
      <edit-wallet-alias
        :no-title="true"
        :wallet="{walletUid: wallet.uuid, shareUid: selectedWallet.uuid, description: selectedWallet.description}"
        :user-type="userType"
        @wallet-updated="alias => onWalletUpdated(alias)"
        @cancel="() => { $bvModal.hide(`edit-wallet-${index}-${userType}-modal`) }"
      />
    </b-modal>

    <!-- Merge Wallets Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`merge-accounts-${index}-${userType}-modal`"
      :title="`Merge Accounts into ${selectedWallet.description}`"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <merge-wallets
        :merge-into="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :wallets="accounts"
        :share-types="shareTypes"
        @back="() => { $bvModal.hide(`merge-accounts-${index}-${userType}-modal`) }"
        @wallet-merge-success="() => { $emit('fetchWallet', wallet); $bvModal.hide(`merge-accounts-${index}-${userType}-modal`) }"
      />
    </b-modal>

    <!-- Split Wallet Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`split-account-${index}-${userType}-modal`"
      :title="`Split ${selectedWallet.description}`"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <create-split-wallet
        :wallet-to-split="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :used-banks="usedBanks"
        :is-default-wallet="isDefault"
        @back="() => { $bvModal.hide(`split-account-${index}-${userType}-modal`) }"
        @wallet-config-success="$emit('fetchWallet', wallet); $bvModal.hide(`split-account-${index}-${userType}-modal`)"
      />
    </b-modal>

    <!-- Transfer Fund Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`transfer-fund-${index}-${userType}-modal`"
      :title="`Transfer Fund from ${selectedWallet.description}`"
      hide-footer
      no-close-on-backdrop
    >
      <transfer-fund
        :wallet="selectedWallet"
        :wallet-uid="wallet.uuid"
        :available-family="availableFamily"
        :is-modal="true"
        :user-type="userType"
        :action="walletAction"
        @transfer-success="onActionSuccess(`transfer-fund-${index}-${userType}-modal`)"
        @back="() => { $bvModal.hide(`transfer-fund-${index}-${userType}-modal`) }"
      />
    </b-modal>

    <!-- Withdraw Fund Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`withdraw-fund-${index}-${userType}-modal`"
      :title="`Withdraw Fund from ${selectedWallet.description}`"
      hide-footer
      no-close-on-backdrop
    >
      <withdraw-fund
        :wallet="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :is-modal="true"
        :charges="charges"
        :action="walletAction"
        @back="() => { $bvModal.hide(`withdraw-fund-${index}-${userType}-modal`) }"
        @withdraw-success="onActionSuccess(`withdraw-fund-${index}-${userType}-modal`)"
      />
    </b-modal>

    <!-- Make Reconciliation Modal -->
    <make-edit-reconciliation
      v-if="selectedWallet"
      :index="index"
      :wallet="selectedWallet"
      :parent-wallet="wallet.uuid"
      :user-type="userType"
      @reconciliation-success="$emit('fetchWallet', wallet)"
    />

    <!-- Share Wallet Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`share-wallet-${index}-${userType}-modal`"
      :title="`Share ${selectedWallet.description} Account`"
      hide-footer
      no-close-on-backdrop
    >
      <share-wallet
        :wallet="selectedWallet"
        :parent-wallet="wallet.uuid"
        :user-type="userType"
        :is-modal="true"
        @back="$bvModal.hide(`share-wallet-${index}-${userType}-modal`)"
        @share-success="onActionSuccess(`share-wallet-${index}-${userType}-modal`)"
      />
    </b-modal>

    <!-- Share Revoke Confirm Modal -->
    <confirm-modal
      v-if="selectedWallet"
      :modal="walletAction === 'revoke-share' ? `revoke-sharing-${index}-${userType}-modal` : `revoke-wallet-${index}-${userType}-modal`"
      :title="walletAction === 'revoke-share' ? $t('Revoke Account Sharing') : $t('Revoke Share Invitation')"
      :alert="`${walletAction === 'revoke-share' ? $t('messages.revoke-account-sharing') : $t('messages.revoke-share-invitation')} ${selectedWallet.description}?`"
      alert-variant="none"
      icon="AlertTriangleIcon"
      icon-size="40"
      icon-color="danger"
      ok-variant="danger"
      @confirm="walletAction === 'revoke-share' ? revokeWalletSharing() : revokeWalletShareInvite()"
    >
      <b-form-group
        v-if="walletAction === 'revoke-share'"
        :label="$t('Remarks')"
      >
        <b-form-input
          v-model="shareRevokeRemarks"
          type="text"
          placeholder="Remarks"
        />
      </b-form-group>
    </confirm-modal>

    <!-- Deactivate Wallet Modal -->
    <b-modal
      v-if="selectedWallet"
      :id="`deactivate-wallet-${index}-${userType}-modal`"
      :title="$t('Deactivate Account')"
      no-close-on-backdrop
    >
      <deactivate-wallet :selected-wallet="selectedWallet" />
      <template #modal-footer>
        <b-overlay :show="isLoading">
          <b-button
            class="mr-1"
            @click="() => { selectedWallet = null; walletAction = null; $bvModal.hide(`deactivate-wallet-${index}-${userType}-modal`) }"
          >
            {{ $t('Cancel') }}
          </b-button>
          <span v-if="selectedWallet.balance">
            <b-button
              v-if="selectedWallet.wallet.__typename === 'BankWallet'"
              variant="primary"
              class="mr-1"
              @click="showModal(selectedWallet, `withdraw-fund-${index}-${userType}-modal`)"
            >
              {{ $t('Withdraw to Bank') }}
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              @click="showModal(selectedWallet, `transfer-fund-${index}-${userType}-modal`)"
            >
              {{ $t('Transfer Fund') }}
            </b-button>
          </span>
          <b-button
            v-else
            variant="danger"
            @click="deactivateWallet"
          >
            {{ $t('Deactivate') }}
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BOverlay, VBTooltip, BAlert, BButton, BDropdown, BDropdownItem, BModal, BBadge, BFormGroup, BFormInput, BLink,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import SelectBankingInfo from './reusables/SelectBankingInfo.vue'
import MergeWallets from './WalletActions/MergeWallets.vue'
import CreateSplitWallet from './WalletActions/CreateSplitWallet.vue'
import EditWalletAlias from './WalletActions/EditWalletAlias.vue'
import TransferFund from './WalletActions/TransferFund.vue'
import WithdrawFund from './WalletActions/WithdrawFund.vue'
import ShareWallet from './WalletActions/ShareWallet.vue'
import MakeEditReconciliation from './WalletActions/MakeEditReconciliation.vue'
import DeactivateWallet from './WalletActions/DeactivateWallet.vue'
import ConfirmModal from './reusables/ConfirmModal.vue'
import AltAccountTable from './AltAccountTable.vue'

const { getWalletIcon, isWalletShared, GET_SHARED_DIRECTION } = useWallet()

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BAvatar,
    BOverlay,
    BAlert,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BBadge,
    BFormGroup,
    BFormInput,
    BLink,
    draggable,
    SelectBankingInfo,
    MergeWallets,
    CreateSplitWallet,
    EditWalletAlias,
    TransferFund,
    WithdrawFund,
    ShareWallet,
    MakeEditReconciliation,
    DeactivateWallet,
    ConfirmModal,
    AltAccountTable,
  },
  props: {
    userType: {
      type: String,
      default: () => 'users',
    },
    wallet: {
      type: Object,
      default: () => {},
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    walletFamily: {
      type: Array,
      default: () => [],
    },
    familyType: {
      type: String,
      default: () => 'PERCENTBASED',
    },
    index: {
      type: Number,
      default: () => null,
    },
    isDefault: {
      type: Boolean,
      default: () => false,
    },
    showBalance: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      getWalletIcon,
      isWalletShared,
      GET_SHARED_DIRECTION,
      shareTypes: [
        { text: 'Alt Account', value: 'INTERNALWALLET' },
        { text: 'Bank Account', value: 'BANKWALLET' },
        { text: 'Payroll Account', value: 'PAYROLLWALLET' },
      ],
      altAccounts: [],
      isLoading: false,
      bankingInfo: [],
      selectedWallet: null,
      company: this.$store.state.project.selectedCompany,
      charges: {},
      walletAction: null,
      shareRevokeRemarks: '',
      hoveredAccount: null,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditCompany']),
    fields() {
      return [
        { key: 'account', label: 'Account Name', classes: `${this.familyType === 'PERCENTBASED' ? 'col-5' : 'col-6'} py-1 pl-4` },
        ...(this.familyType === 'PERCENTBASED' ? [{ key: 'sharePercent', label: 'Share Percent', classes: 'col-3' }] : []),
        { key: 'totalBalance', label: 'Total Balance', classes: this.familyType === 'PERCENTBASED' ? 'col-2' : 'col-4' },
        { key: 'actions', label: 'Actions', classes: 'col-2 text-right' },
      ]
    },
    availableBanks() {
      if (this.usedBanks && this.bankingInfo.data) {
        const bankingInfo = this.bankingInfo.data.filter(info => this.usedBanks.findIndex(
          bank => bank.id === info.information.id,
        ) === -1)
        return bankingInfo
      }
      return this.bankingInfo.data
    },
    availableFamily() {
      let excludeOutbound = this.walletFamily
      if (this.userType === 'users') {
        excludeOutbound = this.walletFamily.filter(wallet => wallet.shares.length && (!this.isWalletShared(wallet.shares[0], this.userType) || (this.isWalletShared(wallet.shares[0], this.userType) && wallet.shares[0].shareDirection !== 'OUTBOUND')))
      }

      return excludeOutbound.map(({
        uuid, description, shares, familyType,
      }) => ({
        uuid,
        label: description,
        shares: shares.filter(share => share.uuid !== this.selectedWallet.uuid),
        familyType,
      }))
    },
  },
  watch: {
    accounts: {
      handler(val) {
        if (val) this.altAccounts = val
      },
      immediate: true,
    },
  },
  methods: {
    orderAltAccounts({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        let api = 'updateUserWalletShareOrders'
        const params = {
          walletUid: this.wallet.uuid,
          orderedShareUids: this.altAccounts.map(account => account.uuid),
        }

        if (this.userType === 'company') {
          api = 'updateCompanyWalletShareOrders'
          params.companyUid = this.company
        }

        useApollo[this.userType][api](params).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data[api].message,
            },
          })
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    getCompanyCharges() {
      useApollo.company.getBillingCharges().then(response => {
        this.charges = response.data.workzone.extraInformations?.data[0]?.information
        if (!this.charges) this.charges = {}
      })
    },
    showMergeWalletOption(wallet) {
      if (this.userType === 'users' && this.accounts.length > 1) return true
      if (wallet.wallet.__typename === 'PayrollWallet' && this.accounts.length > 1) return true
      if (this.userType === 'company') {
        if (this.isDefault && this.accounts.length > 2) return true
        if (!this.isDefault && this.accounts.length > 1) return true
      }
      return false
    },
    getWallet() {
      let api = 'getUserWallet'
      let params = {}
      let res = 'me'
      if (this.userType === 'company') {
        api = 'getCompanyWallet'
        params = { company: this.company }
        res = 'company'
      }

      this.isLoading = true
      useApollo[this.userType][api](params).then(response => {
        const data = response.data[res]?.wallet
        this.wallet = data
        this.accounts = data ? data.shares : []

        this.usedBanks = this.wallet?.shares.filter(share => share.wallet.__typename === 'BankWallet').map(bankWallet => ({
          id: bankWallet.wallet.information.id,
          alias: bankWallet.wallet.information.alias,
        }))
      }).finally(() => { this.isLoading = false })
    },
    getWalletType(type) {
      if (type === 'InternalWallet') return 'Alt Account'
      if (type === 'PayrollWallet') return 'Payroll Account'
      return 'Bank Account'
    },
    showBankingInfoModal(wallet) {
      this.selectedWallet = wallet
      if (!this.bankingInfo.length) this.getBankingInfo()

      this.$nextTick(() => { this.$bvModal.show(`select-banking-info-${this.selectedWallet.uuid}-${this.index}-${this.userType}`) })
    },
    getBankingInfo() {
      let api = 'getMyBillingInfo'
      let res = 'me'
      let params = {}

      if (this.userType === 'company') {
        api = 'getCompanyBillingInfo'
        res = 'company'
        params = {
          companyUid: this.company,
        }
      }

      useApollo[this.userType][api](params).then(response => {
        this.bankingInfo = response.data[res]?.extraInformations
      })
    },
    attachWallet(infoId) {
      this.isLoading = true
      this.$bvModal.hide(`select-banking-info-${this.selectedWallet.uuid}-${this.index}-${this.userType}`)
      const info = this.bankingInfo.data.find(bank => bank.information.id === infoId)
      const infoType = info.information.__typename
      this.selectedInfoName = info.information.alias
      const form = {
        wallet: {
          walletUid: this.wallet.uuid,
          shareUid: this.selectedWallet.uuid,
        },
        input: {
          informationId: infoId,
          informationType: infoType.substr(0, infoType.length - 11).toUpperCase(),
        },
      }

      let res = 'attachBankingInformationToInternalWallet'

      if (this.userType === 'company') {
        form.companyUid = this.company
        res = 'attachBankingInformationToCompanyInternalWallet'
      }

      useApollo[this.userType].attachBankToWallet(form).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[res].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetchWallet', this.wallet)
        this.isLoading = false
      })
    },
    revokeWalletShareInvite() {
      this.isLoading = true
      const params = {
        shareUid: this.selectedWallet.uuid,
        checksum: this.selectedWallet.invites.data[0].checksum,
      }
      let api = 'revokeWalletShareInvite'

      if (this.userType === 'company') {
        params.companyUid = this.company
        api = 'revokeCompanyWalletShareInvite'
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetchWallet', this.wallet)
        this.isLoading = false
      })
    },
    revokeWalletSharing() {
      this.isLoading = true
      const params = {
        wallet: {
          walletUid: this.selectedWallet.family.uuid,
          shareUid: this.selectedWallet.uuid,
        },
        remarks: this.shareRevokeRemarks,
      }
      let api = 'revokeWalletShare'

      if (this.userType === 'company') {
        params.companyUid = this.company
        api = 'revokeCompanyWalletShare'
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.$emit('fetchWallet', this.wallet)
          this.isLoading = false
        })
    },
    showModal(wallet, modal, action = null) {
      if (modal === `withdraw-fund-${this.index}-${this.userType}-modal` && !this.charges.length) this.getCompanyCharges()
      if (action === 'deactivate') this.walletAction = action
      this.selectedWallet = wallet
      this.$nextTick(() => { this.$bvModal.show(modal) })
    },
    onActionSuccess(modal) {
      this.$bvModal.hide(modal)
      this.$emit('fetchWallet', this.wallet)
      if (this.walletAction === 'deactivate') {
        this.isLoading = true
        setTimeout(() => {
          this.selectedWallet = this.accounts.find(account => account.uuid === this.selectedWallet.uuid)
          this.isLoading = false
        }, 1000)
      } else this.selectedWallet = null
    },
    onWalletUpdated(alias) {
      this.$emit('walletUpdated', this.wallet.uuid, this.selectedWallet.uuid, alias)
      this.$bvModal.hide(`edit-wallet-${this.index}-${this.userType}-modal`)
    },
    deactivateWallet() {
      this.isLoading = true
      const params = {
        wallet: {
          walletUid: this.wallet.uuid,
          shareUid: this.selectedWallet.uuid,
        },
      }
      let api = 'deactivateWalletShare'

      if (this.userType === 'company') {
        api = 'deactivateCompanyWalletShare'
        params.companyUid = this.company
      }
      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetchWallet', this.wallet)
        this.selectedWallet = null
        this.$bvModal.hide(`deactivate-wallet-${this.index}-${this.userType}-modal`)
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
